import React, { ReactElement, FC } from "react"

import { showLogos, showDownloads, ContentBoxWithImage } from "../../utils"
import moment from "moment"

const OrganContent: FC<{ post: any }> = ({ post }) => {
  return (
    <div className="blog-post">
      <h2
        className="text-3xl font-bold pb-2"
        dangerouslySetInnerHTML={{ __html: post.title }}
      ></h2>
      <span className="text-xs pb-2 block text-gray-500">
        Kategória:
        {post.categories && `${post.categories.map(item => ` ${item.name}`)} `}
        {/* {post.date && `Dátum: ${moment(post.date).format("DD.MM.YYYY")}`} */}
      </span>
      <ContentBoxWithImage fluid={post.fluid} html={post.html} />
      <div className="flex flex-col items-center py-8">
        <div className="text-xl border border-gray-500 bg-gray-100 rounded p-4 mb-4">
          <p>
            <span className="font-bold">Názov projektu</span>
            <br /> Rekonštrukcia a reštaurovanie organu v konkatedrále sv.
            Mikuláša Prešov
          </p>{" "}
          <p className="pt-4">
            <span className="font-bold">Prijímateľ podpory</span>
            <br /> Rímskokatolícka farnosť sv. Mikuláša, Prešov
          </p>
          <p className="pt-4">
            <span className="font-bold">Termín realizácie projektu</span>
            <br /> 1.január 2019 - 31.október 2021
          </p>
        </div>
        <table className="table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Popis</th>
              <th className="px-4 py-2">Suma v &euro;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">Výška schválenej dotácie</td>
              <td className="border px-4 py-2">200 000</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border px-4 py-2">Spolufinancovanie *</td>
              <td className="border px-4 py-2">220 240</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">Celkový rozpočet</td>
              <td className="border px-4 py-2">420 240</td>
            </tr>
          </tbody>
        </table>
        <p className="mt-4">
          * - Minimálne spolufinancovanie je 50% z požadovanej dotácie.
        </p>
      </div>

      {/* <div className="flex flex-col items-center py-8">
        <p className="font-bold">
          Projekt je podporený z rozpočtu Prešovského samosprávneho kraja.
        </p>

        <Img
          fluid={logoPsk.childImageSharp.fluid}
          alt="Prešovský samosprávny kraj"
          className="md:w-64"
        />
      </div> */}

      {/* <div className="flex flex-col items-center justify-center mt-4 p-8 bg-gray-300 rounded">
        <p className="text-center">
          Číslo zmluvy o poskytnutí dotácie: Zmluva č. 944/2019/ORR
        </p>

        <a
          href="https://po-kraj.sk/files/zmluvy/10185_2019-09-04_zmluva_944-2019-orr_vyzva-pre-region-pre-rok-2019.pdf"
          className="inline-block mt-4 p-4 bg-blue-700 text-gray-100 rounded"
        >
          <Icon name="attach_file" className="inline" />
          <span>Stiahnuť zmluvu</span>
        </a>
      </div> */}

      {post.logos && showLogos(post.logos)}
      {post.files && showDownloads(post.files)}
    </div>
  )
}

export default OrganContent
