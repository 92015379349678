import React, { FC } from "react"
import useWindowDimensions from "./hooks/useWindowDimensions"
import Img from "gatsby-image"
import Gallery from "./components/Gallery"
import { useImage } from "./hooks/useImage"
import { Icon } from "./components/Icon"

export const showGallery = gallery => {
  return (
    <section className="mt-4 py-8 px-2 bg-gray-300 rounded">
      <h3 className="text-xl mb-6 text-center">Galéria</h3>
      <div>
        <Gallery photos={gallery} />
      </div>
      {/* <div className="flex flex-wrap items-center justify-center"> */}
      {/* {post.gallery.map((item, index) => (
            <a
              href={item.publicUrl}
              className="w-full sm:w-1/2 md:w-1/3"
              key={index}
            >
              <Img fluid={item.fluidSquare} alt={item.title} />
            </a>
          ))} */}
      {/* </div> */}
    </section>
  )
}

export const showDownloads = files => {
  return (
    <section className="mt-4 py-8 px-2 rounded">
      <h3 className="text-xl mb-6 text-center">Dokumenty na stiahnutie</h3>
      <div className="flex flex-wrap items-center justify-center">
        {files.map((file, index) => (
          <li key={index} className="inline-flex items-center">
            {/* <a
              href={file.publicUrl}
              className="inline-block text-sm bg-gray-900 py-1 px-4 m-1 rounded text-gray-100"
              dangerouslySetInnerHTML={{ __html: file.title }}
            ></a> */}
            <a
              href={file.publicUrl}
              className="inline-block  p-2 m-1 bg-blue-700 hover:bg-blue-600 text-gray-100 rounded"
            >
              <Icon name="attach_file" className="inline" />
              <span dangerouslySetInnerHTML={{ __html: file.title }}></span>
            </a>
          </li>
        ))}
      </div>
    </section>
  )
}

export const showLogos = logos => {
  const { height, width } = useWindowDimensions()
  let baseSize = 100
  if (width >= 640) baseSize = 150
  if (width >= 1024) baseSize = 200

  const calculateMaxWidth = (aspectRatio: number): string => {
    // console.log(aspectRatio)
    // let w = (maxArea / width) * aspectRatio
    /*
    x1=300
    y1=100
    p1=30000
    a1=3
    
    x2=100
    y2=100
    p2=10000
    a2=1
  
    a3=3 -> sirka = 3xvyska
    x3=10000/(a3+a)
    a3 * y   * y =p2
    y=sqrt(p2/a3) //y-vyska
    x=sqrt(p2/(1/a3)) //x-vyska
    
    */
    let ratio = 1 / aspectRatio
    let w = Math.sqrt((baseSize * baseSize) / ratio)
    let maxWidth = `${w < width ? w : width}px`
    return maxWidth
  }
  return (
    <section className="mt-4 py-8 px-2">
      <h3 className="text-xl mb-6 text-center">
        Projekt realizovaný s finančnou podporou
      </h3>
      <div className="flex flex-wrap items-center justify-center">
        {logos.map((logo, index) => {
          return (
            <div
              className="w-full m-2"
              key={index}
              style={{
                maxWidth: calculateMaxWidth(logo.aspectRatio),
              }}
            >
              <Img fluid={logo.fluid} />
            </div>
          )
        })}
      </div>
      <ul></ul>
    </section>
  )
}

export const ContentBoxWithImage: FC<{ fluid: any; html: string }> = ({
  fluid,
  html,
}) => {
  const { project } = useImage()
  return (
    <div className="flex flex-col items-center md:flex-row-reverse bg-gray-300 rounded p-4 border border-gray-700">
      <div className="flex-none m-2 w-full md:w-64">
        <Img
          fluid={fluid ? fluid : project.childImageSharp.fluid}
          className="w-48 m-auto rounded-lg"
        />
      </div>

      <div
        className="m-2 w-full"
        dangerouslySetInnerHTML={{ __html: html }}
      ></div>
    </div>
  )
}
