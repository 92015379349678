import React, { ReactElement, FC } from "react"
import moment from "moment"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Img from "gatsby-image"
import { Category } from "../models/Post"
import OrganContent from "../templates/content/OrganContent"
import {
  showLogos,
  showDownloads,
  showGallery,
  ContentBoxWithImage,
} from "../utils"

declare var window: any

const PostTemplate = ({ data }): ReactElement => {
  const post = usePost(data)

  let CustomContent = null
  if (
    post.slug ==
    "rekonstrukcia-a-restaurovanie-organu-v-konkatedrale-sv-mikulasa-presov"
  )
    CustomContent = OrganContent

  return (
    <Layout>
      <Seo title={post.title} />
      {CustomContent && <CustomContent post={post} />}
      {!CustomContent && (
        <div className="blog-post">
          <h2
            className="text-3xl font-bold pb-2"
            dangerouslySetInnerHTML={{ __html: post.title }}
          ></h2>

          <span className="text-xs pb-2 block text-gray-500">
            Kategória:
            {post.categories &&
              `${post.categories.map(item => ` ${item.name}`)} `}
            {/* {post.date && `Dátum: ${moment(post.date).format("DD.MM.YYYY")}`} */}
          </span>
          <ContentBoxWithImage fluid={post.fluid} html={post.html} />
          {/* <div className="flex flex-wrap flex-row">
            {post.fluid && (
              <div className="flex-none w-full sm:w-32 sm:mr-3 md:w-64">
                <Img fluid={post.fluid} className="" />
              </div>
            )}
            <div
              className="flex-1"
              dangerouslySetInnerHTML={{ __html: post.html }}
            ></div>
          </div> */}
          {post.gallery && showGallery(post.gallery)}

          {post.logos && showLogos(post.logos)}
          {post.files && showDownloads(post.files)}
        </div>
      )}
    </Layout>
  )
}

export default PostTemplate

const usePost = (data: any) => ({
  html: data.wordpressPost.content,
  // html: data.wordpressPost.content.replace(/classname/gi, "className"),
  //   date: data.markdownRemark.frontmatter.date,
  //   path: data.markdownRemark.frontmatter.path,
  title: data.wordpressPost.title,
  slug: data.wordpressPost.slug,
  date: data.wordpressPost.date,
  fluid:
    data.wordpressPost.featured_media &&
    data.wordpressPost.featured_media.localFile.childImageSharp.fluid,
  featuredMediaUrl:
    data.wordpressPost.featured_media &&
    data.wordpressPost.featured_media.source_url,
  categories: data.wordpressPost.categories.map(
    item =>
      ({
        name: item.name,
        slug: item.slug,
      } as Category)
  ),
  files:
    data.wordpressPost.acf &&
    data.wordpressPost.acf.files &&
    data.wordpressPost.acf.files
      .filter(item => item.file)
      .map(item => ({
        sourceUrl: item.file.source_url,
        publicUrl: item.file.localFile.publicURL,
        title: item.file.title,
      })),
  logos:
    data.wordpressPost.acf &&
    data.wordpressPost.acf.logos &&
    data.wordpressPost.acf.logos
      .filter(
        item =>
          item.logo &&
          item.logo.localFile &&
          item.logo.localFile.childImageSharp
      )
      .map(item => ({
        fluid: item.logo.localFile.childImageSharp.fluid,
        title: item.logo.title,
        aspectRatio: item.logo.localFile.childImageSharp.sizes.aspectRatio,
      })),
  gallery:
    data.wordpressPost.acf &&
    data.wordpressPost.acf.gallery &&
    data.wordpressPost.acf.gallery
      .filter(item => item && item.localFile && item.localFile.childImageSharp)
      .map(item => ({
        fluidSquare: item.localFile.childImageSharp.fluidSquare,
        fluid: item.localFile.childImageSharp.fluid,
        title: item.title,
        publicUrl: item.localFile.publicURL,
        aspectRatio: item.localFile.childImageSharp.sizes.aspectRatio,
      })),
  //   category: data.markdownRemark.frontmatter.category,
  //   download: data.markdownRemark.frontmatter.download,
  //   categoryName: categoryName(data.markdownRemark.frontmatter.category),
  //   fluid: data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid,
  //   fixed: data.markdownRemark.frontmatter.featuredImage.childImageSharp.fixed,
})

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
      content
      slug
      categories {
        name
        slug
      }
      featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      acf {
        files {
          file {
            source_url
            title
            localFile {
              publicURL
            }
          }
        }
        logos {
          logo {
            title
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                sizes {
                  aspectRatio
                }
              }
            }
          }
        }
        gallery {
          title
          localFile {
            publicURL
            childImageSharp {
              fluidSquare: fluid(maxWidth: 1000, maxHeight: 1000) {
                ...GatsbyImageSharpFluid
              }
              fluid {
                ...GatsbyImageSharpFluid
              }
              sizes {
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`
