import { useState, useEffect } from "react"
const windowIsDefined = typeof window !== "undefined"

function getWindowDimensions() {
  if (!windowIsDefined) return { width: 0, height: 0 }
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}
// use: const { height, width } = useWindowDimensions();
export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}
