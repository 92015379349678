import Carousel, { Modal, ModalGateway } from "react-images"
import React, { Component, FC, useState } from "react"

import Img from "gatsby-image"
import useWindowDimensions from "../hooks/useWindowDimensions"

interface GalleryItem {
  fluidSquare: any
  fluid: any
  title: string
  publicUrl?: string
  aspectRatio: number
}

const Gallery: FC<{ photos: GalleryItem[] }> = ({ photos, children }) => {
  const { height, width } = useWindowDimensions()
  const getWidth = (aspectRatio, maxWidth, maxHeight) => {
    const w = parseInt(maxWidth)
    const h = parseInt(maxHeight)
    return `${0.9 * Math.min(w, h * aspectRatio)}px`
  }
  const images = photos.map((photo) =>
    Object.assign({
      src: photo.fluid.src,
      photo: photo,
      title: "some title",
      description: "some description",
    })
  )

  const [index, setIndex] = useState(1)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const CustomView = (props) => {
    const { data } = props
    const myWidth = getWidth(data.photo.aspectRatio, width, height)
    return (
      <div className="">
        <Img
          fluid={data.photo.fluid}
          alt={data.title}
          style={{
            width: myWidth,
            margin: "0 auto", // Used to center the image
          }}
        />
      </div>
    )
  }

  const openLightbox = (photoIndex, event) => {
    event.preventDefault()
    setIndex(photoIndex)
    setModalIsOpen(true)
  }

  const closeLightbox = () => {
    setModalIsOpen(false)
  }

  return (
    <div className="flex flex-wrap justify-center">
      {photos.map((photo, i) => (
        <a
          href={photo.fluid.src}
          // onClick={(e) => openLightbox(i, e)}
          key={i}
          className="w-1/2 sm:w-1/4 md:w-1/6 p-1"
        >
          <Img className="" fluid={photo.fluidSquare} />
        </a>
      ))}

      {/* <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel views={images} currentIndex={index} />
            <Carousel
              views={images}
              currentIndex={index}
              components={{ View: CustomView }}
            />
          </Modal>
        ) : null}
      </ModalGateway> */}
    </div>
  )
}
export default Gallery
